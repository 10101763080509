























































import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import Loader from "../Loader.vue";
import { ActionType } from "@/data/models/ActionTypes";
import EntitySelect from "@/components/EntitySelect.vue";
import Form from "@/components/forms/EnumForm.vue";
import DatePicker from "@/components/DatePicker.vue";
@Component({
  components: {
    FormContainer,
    Loader,
    EntitySelect,
    DatePicker
  }
})
export default class ProjectCommunicationActivityForm extends Vue {
  @Prop()
  public id!: string;

  @Prop()
  public projectId!: string;

  @Prop()
  public partners!: DataProvider<any>;

  public provider!: DataProvider<any>;

  public crud!: ICrudClient<any>;

  private item: any = null;

  private form = Form;

  created() {
    this.provider = this.$service.providers.communicationActivities;
    this.crud = this.$service.crud.communicationActivities;
  }
  async mounted() {
    if (this.id === "new") {
      this.item = {
        comment: "",
        date: "",
        valueId: "",
        projectId: this.projectId
      };
    }
  }
}
