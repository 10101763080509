



















































































import { Component, Prop, Vue } from "vue-property-decorator";
import DataProvider from "@/lib/DataProvider";
import { Project } from "@/data/models/Projects";
import ModelTable from "@/components/ModelTable.vue";
import activitiesColumns from "@/data/columns/CommunicationActivities";
import ProjectCommunicationActivityForm from "@/components/forms/ProjectCommunicationActivityForm.vue";
import ImportDialog from "@/components/ImportDialog.vue";

import { saveAs } from "file-saver";
@Component({
  components: { ModelTable, ProjectCommunicationActivityForm, ImportDialog }
})
export default class ProjectCommunicationActivities extends Vue {
  @Prop()
  public id!: string;
    @Prop()
  public title!: string;

  private activitiesColumns: any[] = activitiesColumns;
  private provider: DataProvider<any> | null = null;
  private selected: any[] = [];
  private selectedItem: any = null;
  private addActivityDialog = false;
  private importDialog = false;

  @Prop()
  public partners!: DataProvider<any>;
  mounted() {
    this.initialize();
  }

  initialize() {
    this.provider = this.$service.providers.projectCommunicationActivities(
      this.id
    );
  }

  async download() {
  const utc = new Date().toJSON().slice(0,10);
    saveAs(
      await this.$service.downloadProjectCommunicationActivities(this.id),
      `CommunicationActivities_${this.title}_${utc}.xlsx` 
    );
  }
}
