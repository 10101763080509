





















































import { Component, Vue, Prop } from "vue-property-decorator";
import ModelTable from "@/components/ModelTable.vue";
import { Location } from "vue-router";
import DataProvider from "@/lib/DataProvider";
import ICrudClient from "@/lib/ICrudClient";
import { Project } from "@/data/models/Projects";
import ProjectCommunicationActivities from "@/components/ProjectCommunicationActivities.vue";
import ProjectExtractions from "@/components/extractionForms/ProjectExtractionsForm.vue";

@Component({
  components: {
    ModelTable,
    ProjectCommunicationActivities,
    ProjectExtractions
  },
})
export default class ProjectsTable extends Vue {
  @Prop()
  public provider!: DataProvider<Project>;

  @Prop()
  public crud!: ICrudClient<Project>;

  @Prop()
  public columns!: any[];

  @Prop()
  public addRoute!: (id: string) => Location;

  @Prop()
  public viewRoute!: (id: string) => Location;

  private openDialog = false;

  private navigateToRoute(route: (id: string) => Location, id: any) {
    if (!route) {
      return;
    }
    this.$router.push(route(id));
  }
}
