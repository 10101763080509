export default [
  {
    name: "date",
    required: true,
    label: "Date",
    align: "left",
    sortable: true,
    field: (i: any) => {
      if (i.date) {
        return new Date(Date.parse(i.date));
      }
      return undefined;
    },
    format: (val: Date) => (val ? val.toLocaleDateString() : "")
  },
  {
    name: "Partner/Name",
    required: true,
    label: "Project Promoter",
    align: "left",
    sortable: true,
    field: (i: any) => (i && i.projectPartner ? i.projectPartner.name : "")
  },
  {
    name: "value",
    required: true,
    label: "Value",
    align: "left",
    sortable: true,
    field: (i: any) => i.value.value
  },
  {
    name: "comments",
    required: true,
    label: "Comments",
    align: "left",
    sortable: true,
    field: (i: any) => i.comment.substring(0, 200)
  }
];
