













































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "@/components/FormContainer.vue";
import DatePicker from "@/components/DatePicker.vue";

@Component({
  components: {
    FormContainer,
    DatePicker,
  },
})
export default class ProjectExtractions extends Vue {
  @Prop()
  public provider!: any;

  private roles: any = [];
  private filters: any = [];

  @Watch("filters", { deep: true })
  onItemChange() {
    if (!this.filters) {
      this.initializeItem();
    }
  }

  async mounted() {
    const items = (await this.$service.providers.extractionProjects.fetchItemsAsync()).items;

    this.roles = items.filter((i: any) => i.roleInPartnership != null)
    this.roles = this.getDinstinct(this.roles.map((i: any) => i.roleInPartnership.value))

    this.initializeItem();
  }

  getDinstinct(arr: any) {
    return Array.from(
      arr.filter(
        (value: any, index: any, self: any) => self.indexOf(value) === index
      )
    );
  }

  initializeItem() {
    this.filters = {
      types: [],
      roles: [],
      startDate: null,
      endDate: null
    }
  }
}
